// Generated by Framer (667f196)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import Tab from "https://framerusercontent.com/modules/M42MM5QoUdAgIsIpdSJx/tzXSeR7bB4rZke2ocz0Z/VAMUDsO6f.js";
const TabFonts = getFonts(Tab);

const cycleOrder = ["cCiC6bf_p", "nciIlf1K_", "fbgk2Qrmf"];

const variantClassNames = {cCiC6bf_p: "framer-v-190dj1m", fbgk2Qrmf: "framer-v-en0yx3", nciIlf1K_: "framer-v-m9i5q6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Tab 1": "cCiC6bf_p", "Tab 2": "nciIlf1K_", "Tab 3": "fbgk2Qrmf"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tab1?: string; tab2?: string; tab3?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "cCiC6bf_p", tab1: MSGp_zYf0 = "Tab 1", tab2: f72tcvvi2 = "Tab 2", tab3: GuQmBQnFI = "Tab 3", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "cCiC6bf_p", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const tap1ua3tv1 = activeVariantCallback(async (...args) => {
setVariant("cCiC6bf_p")
})

const tapu9wcpq = activeVariantCallback(async (...args) => {
setVariant("nciIlf1K_")
})

const tap1115orz = activeVariantCallback(async (...args) => {
setVariant("fbgk2Qrmf")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-JNYJr", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-190dj1m", className)} data-framer-name={"Tab 1"} layoutDependency={layoutDependency} layoutId={"cCiC6bf_p"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({fbgk2Qrmf: {"data-framer-name": "Tab 3"}, nciIlf1K_: {"data-framer-name": "Tab 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1om09sp-container"} layoutDependency={layoutDependency} layoutId={"iuXsJHTj6-container"} transition={transition}><Tab height={"100%"} id={"iuXsJHTj6"} layoutId={"iuXsJHTj6"} title={MSGp_zYf0} variant={"Ho9BqSUe0"} width={"100%"} {...addPropertyOverrides({fbgk2Qrmf: {tap: tap1ua3tv1, variant: "ym0P2kopY"}, nciIlf1K_: {tap: tap1ua3tv1, variant: "ym0P2kopY"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-1gve4xv-container"} layoutDependency={layoutDependency} layoutId={"TQw6EZXgc-container"} transition={transition}><Tab height={"100%"} id={"TQw6EZXgc"} layoutId={"TQw6EZXgc"} tap={tapu9wcpq} title={f72tcvvi2} variant={"ym0P2kopY"} width={"100%"} {...addPropertyOverrides({nciIlf1K_: {variant: "Ho9BqSUe0"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-1fec0r3-container"} layoutDependency={layoutDependency} layoutId={"sKwhlQb0E-container"} transition={transition}><Tab height={"100%"} id={"sKwhlQb0E"} layoutId={"sKwhlQb0E"} tap={tap1115orz} title={GuQmBQnFI} variant={"ym0P2kopY"} width={"100%"} {...addPropertyOverrides({fbgk2Qrmf: {variant: "Ho9BqSUe0"}}, baseVariant, gestureVariant)}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-JNYJr [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-JNYJr * { box-sizing: border-box; }", ".framer-JNYJr .framer-1ea09ek { display: block; }", ".framer-JNYJr .framer-190dj1m { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-JNYJr .framer-1om09sp-container, .framer-JNYJr .framer-1gve4xv-container, .framer-JNYJr .framer-1fec0r3-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-JNYJr .framer-190dj1m { gap: 0px; } .framer-JNYJr .framer-190dj1m > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-JNYJr .framer-190dj1m > :first-child { margin-left: 0px; } .framer-JNYJr .framer-190dj1m > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 49
 * @framerIntrinsicWidth 275
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"nciIlf1K_":{"layout":["auto","auto"]},"fbgk2Qrmf":{"layout":["auto","auto"]}}}
 * @framerVariables {"MSGp_zYf0":"tab1","f72tcvvi2":"tab2","GuQmBQnFI":"tab3"}
 */
const Framerc03HF2wbd: React.ComponentType<Props> = withCSS(Component, css, "framer-JNYJr") as typeof Component;
export default Framerc03HF2wbd;

Framerc03HF2wbd.displayName = "Elements/Tabs";

Framerc03HF2wbd.defaultProps = {height: 49, width: 275};

addPropertyControls(Framerc03HF2wbd, {variant: {options: ["cCiC6bf_p", "nciIlf1K_", "fbgk2Qrmf"], optionTitles: ["Tab 1", "Tab 2", "Tab 3"], title: "Variant", type: ControlType.Enum}, MSGp_zYf0: {defaultValue: "Tab 1", displayTextArea: false, title: "Tab 1", type: ControlType.String}, f72tcvvi2: {defaultValue: "Tab 2", displayTextArea: false, title: "Tab 2", type: ControlType.String}, GuQmBQnFI: {defaultValue: "Tab 3", displayTextArea: false, title: "Tab 3", type: ControlType.String}} as any)

addFonts(Framerc03HF2wbd, [...TabFonts])